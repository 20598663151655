<script lang="ts">
    import { onMount, afterUpdate } from 'svelte';
    import type { Post, User } from '../../interfaces/types';
    import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';
    import 'swiper/swiper-bundle.min.css';

    import CheckoutDialog from '../checkout/CheckoutDialog.svelte';
    import ForwardChevron from '@/Icons/ForwardChevron.svelte';
    import BackwardChevron from '@/Icons/BackwardChevron.svelte';
    import LockClosedIcon from '@/Icons/LockClosedIcon.svelte';

    export let post: Post;
    export let data;
    export let type: 'post' | 'message';
    export let multi: boolean;
    export let currentUser: User | null;
    export let paymentSettings: any;
    export let loggedUserAvailableAmount: number;

    let swiper: Swiper;
    let swiperContainer: HTMLElement;
    let showCheckoutDialog = false;

    function initSwiper() {
        if (swiper && !swiper.destroyed) {
            swiper.destroy();
        }

        Swiper.use([Pagination, Autoplay, Navigation]);
        swiper = new Swiper(swiperContainer, {
            slidesPerView: 1,
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    onMount(() => {
        if (multi) {
            initSwiper();
        }
    });

    afterUpdate(() => {
        if (multi && swiperContainer) {
            initSwiper();
        }
    });

    function creatorCanEarnMoney(user: User): boolean {
        return true;
    }

    function getLoggedUserAvailableAmount(): number {
        return 0;
    }

    function handleUnlock() {
        if (!currentUser) {
            // Trigger login modal
            console.log('Trigger login modal');
            return;
        }

        if (!creatorCanEarnMoney(post.user)) {
            return;
        }

        showCheckoutDialog = true;
    }
</script>

<div class="px-md-0 px-3">
    <div class="post-media-image relative">
        <div class="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-white opacity-30">
            <LockClosedIcon size="size-48" />
        </div>
        {#if !multi}
            <img class="w-100 h-100" src="{post.attachments[0].blurred_version}" alt="blurred Image" />
        {:else}
            <div class="swiper-container mySwiper pointer-cursor" bind:this="{swiperContainer}">
                <div class="swiper-wrapper">
                    {#each post.attachments as attachment}
                        <div class="swiper-slide">
                            <img
                                class="w-100 h-100"
                                src="{attachment.blurred_version}"
                                alt="blurred Image"
                                style="margin-bottom:1rem"
                            />
                        </div>
                    {/each}
                </div>
                <div class="swiper-button swiper-button-next text-white">
                    <ForwardChevron />
                </div>
                <div class="swiper-button swiper-button-prev text-white">
                    <BackwardChevron />
                </div>
            </div>
        {/if}
    </div>

    {#if type === 'post'}
        <div class="col-12" style="margin-top:1rem">
            <button
                class="btn btn-outline-primary btn-block to-tooltip"
                class:disabled="{!creatorCanEarnMoney(post.user)}"
                on:click="{handleUnlock}"
                title="{!creatorCanEarnMoney(post.user) ? 'This creator cannot earn money yet' : ''}"
            >
                Unlock post for {post.price}
            </button>

            {#if post.price_type === 'auction'}
                <div class="text-center">or</div>

                <a href="/post-bid#post_bid_{post.id}">
                    <button
                        class="btn btn-outline-primary btn-block to-tooltip"
                        class:disabled="{!creatorCanEarnMoney(post.user)}"
                    >
                        Bid the post
                    </button>
                </a>
            {/if}
        </div>
    {/if}
</div>
<div class="swiper-pagination z-100"></div>

<CheckoutDialog
    bind:show="{showCheckoutDialog}"
    user="{post.user}"
    {data}
    {paymentSettings}
    authenticatedUser="{currentUser}"
    userAvailableCredit="{loggedUserAvailableAmount ?? data.loggedUserAvailableAmount}"
    transactionType="post-unlock"
/>

<style lang="postcss">
    :global(.swiper-container) {
        @apply h-full w-full;
    }

    :global(.swiper-pagination) {
        @apply relative bottom-0 left-0 mt-4 flex w-full justify-center;
    }

    :global(.swiper-pagination-bullet) {
        @apply mx-1 inline-block h-2 w-2 rounded-full bg-black opacity-20;
    }

    :global(.swiper-pagination-bullet-active) {
        @apply bg-indigo-600 opacity-100;
    }
</style>
